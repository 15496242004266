import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Button } from "@material-ui/core";
import axios from "axios";
import ReactSelect from "react-select";
import "./App.css";
import Header from "./Header.js";
import Footter from "./Footter.js";
import moment from "moment";

export default function App() {
  //住所格納用
  const [pref, setPref] = useState("");

  const [address1, setAddress1] = useState("");
  const [address1Label, setAddress1Label] = useState("");
  const [address1List, setAddress1List] = useState([]);

  const [address2, setAddress2] = useState("");
  const [address2Label, setAddress2Label] = useState("");
  const [address2List, setAddress2List] = useState([]);

  const [address3, setAddress3] = useState("");
  const [address3Label, setAddress3Label] = useState("");
  const [address3List, setAddress3List] = useState([]);

  const [pageState, setPageState] = useState(2);
  const pageState2 = 0; //変数無理やり使う用
  const [updatetime, setUpdatetime] = useState("");

  useEffect(() => {
    /* 第1引数には実行させたい副作用関数を記述*/
    (async () => {
      // console.log("副作用関数が実行されました！");
      // const apiPath = `https://v4azz0ksse.execute-api.ap-northeast-1.amazonaws.com/dev/items`;
      const apiPath = `https://fdcygel02a.execute-api.ap-northeast-1.amazonaws.com/prd/items`;
      await axios.post(apiPath, {
        mode: 99,
      });
    })();
  }, []);

  //都道府県選択時
  const _setPref = async (data) => {
    setPref(data);

    //下の選択肢を初期化
    setAddress1("");
    setAddress1List([]);
    setAddress1Label(null);
    setAddress2("");
    setAddress2List([]);
    setAddress2Label(null);
    setAddress3("");
    setAddress3List([]);
    setAddress3Label(null);
    setPageState(2);

    //一つ下のリストを暫定置き換え
    setAddress1List([{ value: "Loading", label: "Loading..." }]);
    //下の住所リストを持ってくる
    // const apiPath = `https://v4azz0ksse.execute-api.ap-northeast-1.amazonaws.com/dev/items`;
    const apiPath = `https://fdcygel02a.execute-api.ap-northeast-1.amazonaws.com/prd/items`;
    const response = await axios.post(apiPath, {
      mode: 0,
      code: data,
    });

    //リストを初期化して、1つずつ配列に追加
    const address1Listtemp = [];
    response.data.Items.forEach((element) => {
      address1Listtemp.push({
        value: element.address1Name,
        label: element.address1Name,
      });
      return null;
    });
    setAddress1List(address1Listtemp);
  };

  //住所1選択時
  const _setAddress1 = async (data, label) => {
    setAddress1(data);
    if (data == null) {
      return;
    }

    setAddress1Label({
      value: data,
      label: label,
    });

    //下の選択肢を初期化
    setAddress2("");
    setAddress2List([]);
    setAddress2Label(null);
    setAddress3("");
    setAddress3List([]);
    setAddress3Label(null);
    setPageState(2);

    //一つ下のリストを暫定置き換え
    setAddress2List([{ value: "Loading", label: "Loading..." }]);
    //下の住所リストを持ってくる
    // const apiPath = `https://v4azz0ksse.execute-api.ap-northeast-1.amazonaws.com/dev/items`;
    const apiPath = `https://fdcygel02a.execute-api.ap-northeast-1.amazonaws.com/prd/items`;
    const response = await axios.post(apiPath, {
      mode: 1,
      code: pref + data,
    });

    //リスト初期化して、1つずつ配列に追加
    const address2Listtemp = [];
    response.data.Items.forEach((element) => {
      address2Listtemp.push({
        value: element.address2Name,
        label: element.address2Name,
      });
      return null;
    });
    setAddress2List(address2Listtemp);
  };

  //住所2選択時
  const _setAddress2 = async (data, label) => {
    setAddress2(data);
    if (data == null) {
      return;
    }

    setAddress2Label({
      value: data,
      label: label,
    });

    //下の選択肢を初期化
    setAddress3("");
    setAddress3List([]);
    setAddress3Label(null);
    setPageState(2);

    //一つ下のリストを暫定置き換え
    setAddress3List([{ value: "Loading", label: "Loading..." }]);
    //下の住所リストを持ってくる
    // const apiPath = `https://v4azz0ksse.execute-api.ap-northeast-1.amazonaws.com/dev/items`;
    const apiPath = `https://fdcygel02a.execute-api.ap-northeast-1.amazonaws.com/prd/items`;
    const response = await axios.post(apiPath, {
      mode: 2,
      code: pref + address1 + data,
    });

    //リスト初期化して、1つずつ配列に追加
    const address3Listtemp = [];

    //住所3がないものは選択を自動化
    if (response.data.Count === 0) {
      address3Listtemp.push({
        value: " ",
        label: "選択肢なし",
      });
      setAddress3List(address3Listtemp);
      setAddress3Label({
        value: " ",
        label: "選択肢なし",
      });
      setAddress3(" ");
    } else {
      response.data.Items.forEach((element) => {
        address3Listtemp.push({
          value: element.address3Name,
          label: element.address3Name,
        });
        return null;
      });
      setAddress3List(address3Listtemp);
    }
  };

  const _setAddress3 = async (data, label) => {
    setAddress3(data);
    setAddress3Label({
      value: data,
      label: label,
    });
    setPageState(2);
  };

  // set states of calendar date
  const [calDate, setCalDate] = useState(new Date());
  function onChange(calDate) {
    // change results based on calendar date click
    setCalDate(calDate);
  }
  // let startDate = new Date();
  // startDate.setDate(startDate.getDate() + 20);
  let startDate = moment().add(20, "days").startOf("month").toDate(); //3週間後
  // let endDate = new Date();
  // endDate.setMonth(endDate.getMonth() + 2);
  let endDate = moment().add(2, "months").endOf("month").toDate(); //2か月後の月末

  //スケジュール格納用
  const [schedule, setSchedule] = useState("");

  //Dateをyymmdd形式に整形
  function formatDate(date) {
    let year = date.getFullYear();
    let month = ("0" + String(Number(date.getMonth()) + 1)).slice(-2); //なぜか1たりない
    let day = ("0" + date.getDate()).slice(-2);
    return year + month + day;
  }

  // activeStartDateの保持用;
  const activeStartDateRef = useRef("");
  function setActiveStartDateRef({ activeStartDate }) {
    activeStartDateRef.current = formatDate(activeStartDate);
    // console.log("activeStartDateChange:", activeStartDateRef.current);
  }

  //住所選択後、ボタン押下時
  const getSchedule = async (event) => {
    event.preventDefault();

    //住所3まで必須判定
    if (address3 === "" || address3 === undefined) {
      alert("すべて選択してください");
      return null;
    }

    // const apiPath = `https://v4azz0ksse.execute-api.ap-northeast-1.amazonaws.com/dev/items`;
    const apiPath = `https://fdcygel02a.execute-api.ap-northeast-1.amazonaws.com/prd/items`;
    // console.log("POST STARTED");

    try {
      const response = await axios.post(apiPath, {
        mode: 10,
        code: pref + address1 + address2 + address3.trim(),
      });
      setSchedule(response.data.schedule.schedule);
      if (response.data.flag !== "0") {
        setPageState(1);
      } else {
        setPageState(0);
      }
      setUpdatetime(response.data.updatetime);
      // console.log(response.data);
    } catch (error) {
      alert("問合せエラー");
    }
  };

  const getTileContent = ({ activeStartDate, date }) => {
    if (String(schedule) === "") {
      return null;
    }
    // console.log(schedule.length);
    // console.log(schedule[0]);

    let AMsche = ""; //AM予定
    let PMsche = ""; //PM予定
    let day = formatDate(date); //dateが被ってるので区別用に

    for (let i = 0; i < schedule.length; i++) {
      // console.log(schedule[i].date);
      if (Number(schedule[i].date) === Number(day)) {
        switch (schedule[i].morning) {
          case 0:
            AMsche = "×";
            break;
          case 1:
            AMsche = "△";
            break;
          case 2:
            AMsche = "〇";
            break;
          case 9:
          default:
            AMsche = "□";
            break;
        }
        switch (schedule[i].afternoon) {
          case 0:
            PMsche = "×";
            break;
          case 1:
            PMsche = "△";
            break;
          case 2:
            PMsche = "〇";
            break;
          case 9:
          default:
            PMsche = "□";
            break;
        }
        return (
          <span>
            <br />
            AM：{AMsche}
            <br />
            PM：{PMsche}
          </span>
        );
      }
    }
  };

  const prefList = [
    { value: "北海道", label: "北海道" },
    { value: "青森県", label: "青森県" },
    { value: "岩手県", label: "岩手県" },
    { value: "宮城県", label: "宮城県" },
    { value: "秋田県", label: "秋田県" },
    { value: "山形県", label: "山形県" },
    { value: "福島県", label: "福島県" },
    { value: "茨城県", label: "茨城県" },
    { value: "栃木県", label: "栃木県" },
    { value: "群馬県", label: "群馬県" },
    { value: "埼玉県", label: "埼玉県" },
    { value: "千葉県", label: "千葉県" },
    { value: "東京都", label: "東京都" },
    { value: "神奈川県", label: "神奈川県" },
    { value: "新潟県", label: "新潟県" },
    { value: "富山県", label: "富山県" },
    { value: "山梨県", label: "山梨県" },
    { value: "長野県", label: "長野県" },
    { value: "静岡県", label: "静岡県" },
  ];

  return (
    <>
      <Header />
      <div className="mainContent">
        <div className="address">
          <div className="address_select">
            <span className="colum">都道府県：</span>
            <br />
            <ReactSelect
              placeholder="選択または検索"
              options={prefList}
              onChange={(event) => _setPref(event.value, event.label)}
            />
          </div>
          <div className="address_select">
            <span className="colum">住所1：</span>
            <br />
            <ReactSelect
              placeholder="選択または検索"
              options={address1List}
              value={address1Label}
              noOptionsMessage={() => "上位項目を選択してください"}
              onChange={(event) => _setAddress1(event.value, event.label)}
            />
          </div>
          <div className="address_select">
            <span className="colum">住所2：</span>
            <br />
            <ReactSelect
              placeholder="選択または検索"
              options={address2List}
              value={address2Label}
              noOptionsMessage={() => "上位項目を選択してください"}
              onChange={(event) => _setAddress2(event.value, event.label)}
            />
          </div>

          <div className="address_select">
            <span className="colum">住所3：</span>
            <br />
            <ReactSelect
              placeholder="選択または検索"
              options={address3List}
              value={address3Label}
              noOptionsMessage={() => "上位項目を選択してください"}
              onChange={(event) => _setAddress3(event.value, event.label)}
            />
          </div>

          {/* <form onSubmit={(event) => getSchedule(event)}>
            <button>検索</button>
          </form> */}
          <div className="divbutton">
            <Button
              className="button"
              variant="outlined"
              color="primary"
              // size="small"
              onClick={(event) => getSchedule(event)}
            >
              検索
            </Button>
          </div>

          <div className="annotation">
            検索欄で住所が表示された場合についても、
            <br />
            専用線の提供を確約するものではありません。
          </div>
        </div>

        {pageState === 1 && (
          <div className="calender-frame">
            <div className="result-calendar">
              <Calendar
                tileContent={getTileContent}
                onChange={onChange}
                value={calDate}
                onActiveStartDateChange={setActiveStartDateRef}
                // maxDetail={"month"}
                minDetail={"year"}
                minDate={startDate}
                maxDate={endDate}
              />
              <div>
                <div className="update">最終更新：{updatetime}</div>
                <br />
                <br />
                上記は参考情報であり、工事日を確約するものではありません。
                <br />
                〇：空きあり　△：空きわずか　×：空きなし
                <br />
                <span>□</span>
                <span className="smallfont">
                  ：状況が表示できないため、恐れ入りますが通常のフローに基づいて申し込みをお願いいたします
                </span>
              </div>
            </div>
          </div>
        )}
        {pageState === 0 && (
          <div className="box_outofservice">
            当該エリアは状況が表示できないため、恐れ入りますが通常のフローに基づいて申し込みをお願いいたします。
          </div>
        )}
        {pageState2 === 1 && (
          <div>
            <span>都道府県：{pref}</span>
            <br />
            <span>住所1：{address1}</span>
            <br />
            <span>住所2：{address2}</span>
            <br />
            <span>住所3：{address3}</span>
          </div>
        )}
      </div>
      <Footter />
    </>
  );
}
