import "./HeaderFooter.css";

import React from "react";

const Footter = () => {
  return (
    <div>
      <footer>
        <div className="footer_wrap">
          <p className="copy pc_ta_c">© 2021 東日本電信電話株式会社</p>
        </div>
      </footer>
    </div>
  );
};

export default Footter;