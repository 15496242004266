import "./HeaderFooter.css";

import logo_wide_02 from "./file/logo_wide_02.png";

import React from "react";

const Header = () => {
  return (
    <div>
      <header id="header">
        <div className="header_wrap">
          <p className="logo_wrap">
            <span className="site_logo">
              <span className="ff_g">工事空き状況</span>
            </span>
          </p>
          <ul className="header_menu">
            <li className="character"></li>
            <li>
              <img src={logo_wide_02} alt="NTT東日本" />
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
};

export default Header;
